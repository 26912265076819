.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}

.success-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #006FFF 0%, #006FFF 100%);
  box-shadow: 0px 10px 20px -2px rgba(0, 111, 255, 0.3);
  border-radius: 50%;

  margin-bottom: 1.5rem;
  img {
    width: 44px;
    height: auto;
  }
}

.success-title {
  font-size: 28px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  color: $color-black;
  margin: 0;
}

.success-description {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: $color-gray;
  margin: 0.5rem 0 0 0;
}

.success-button {
  margin-top: 2rem;
}