.mobile_nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;

  width: 300px;
  height: 100vh;
  background-color: #fff;

  transition: transform $trans--600;
  transform: translateX(100%);

  opacity: 0;

  &__link {
    color: $color-black;
    transition: $trans--300;
    text-align: left;
    &:not(:last-child){
      margin-bottom: 2rem;
    }
    &:hover,
    &:active {
      color: $color-blue;
    }
  }
}

.mobile_nav.active {
  transform: translateX(0);
}

.mobile_nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.5rem 0 1.5rem 0;
  margin: 0 1rem 0 1.5rem;
}

.mobile_nav-body {
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0 2.5rem 0;
  margin: 0 1rem 2.5rem 1.5rem;
  border-bottom: 1px solid $color-secondary-1;
}

.mobile_nav-footer {
  display: flex;
  flex-direction: column;

  // padding: 1.5rem 0 2.5rem 0;
  margin: 0 1rem 0 1.5rem;
}

.mobile_nav_overlay {
  display: none;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(48, 48, 48, 0.25);
    z-index: 900
  }
}
