.sertificate {
  @include vp--1439 {
    .tns-ovh {
      margin-right: 0;
    }
  }

  @include vp--1023 {
    .tns-ovh {
      margin-right: -1.5rem;
    }
  }

  @include vp--767 {
    .tns-ovh {
      margin-right: -1rem;
    }
  }
}

.sertificate-title {
  margin-bottom: 2rem;
  @media only screen and (min-width: 576px){
    margin-bottom: 2.5rem
  }
  @media only screen and (min-width: 768px){
    margin-bottom: 3rem
  }
  @media only screen and (min-width: 992px){
    margin-bottom: 3.5rem
  }
}

.sertificate-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sertificate-item {
  border-radius: 0.5rem;
}