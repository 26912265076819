.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans--600,
  transform $trans--600,
  visibility $trans--600;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: flex-end;
    @media only screen and (min-width: 576px){
      align-items: center;
    }
    width: 100%;
    min-height: 100%;
    padding: 2rem 0 0 0;
    @media only screen and (min-width: 576px){
      padding: 2rem 0;
    }

    // @include vp--767 {
    //   padding: 60px 16px;
    // }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;
    width: 600px;
    background-color: $color-white;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.15);
    border-radius: 12px 12px 0 0;
    @media only screen and (min-width: 576px) {
      width: 565px;
      border-radius: 12px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;

    padding: 0;

    background: $color-transparent;
    border: none;
    cursor: pointer;
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp--767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }
}

.modal-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 1;
  margin: 0 0 1.5rem 0;
  @media only screen and (min-width: 992px){
    font-size: 28px;
    margin: 0 0 2rem 0;
  }
}

.modal-body {

  &__button {
    display: none;
    margin-top: 2rem;
    @media only screen and (min-width: 576px){
      display: block;
    }
  }
}

// .modal-body_fixed {
//   .simplebar-scrollbar:before {
//     background: $color-gray;
//   }
//   .simplebar-track {
//     width: 8px!important;
//   }
// }

// .modal-body_calc {
//   @media only screen and (max-width: 576px){
//     overflow-y: scroll;
//     max-height: calc(100vh - 14rem + 1px);
//     margin-bottom: 7rem;
//     margin-right: -1rem;
//     padding-right: 1rem;
//   }
// }

// .modal-body_checkout {
//   @media only screen and (max-width: 576px){
//     overflow-y: scroll;
//     max-height: calc(100vh - 13rem + 1px);
//     margin-bottom: 5rem;
//     margin-right: -1rem;
//     padding-right: 1rem;
//     padding-bottom: 1rem;
//   }
// }

.modal-static {
  padding: 1rem;
  @media only screen and (min-width: 576px) {
    width: 565px;
    padding: 2rem;
  }
}

.modal-fixed {
  @media only screen and (min-width: 576px){
    display: none;
  }
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1300;

  background-color: $color-white;
  border: 1px solid #E5E5E5;
  border-radius: 12px 12px 0px 0px;
  padding: 1.5rem 1rem;
}

.modal-footer {
  display: none;
  @media only screen and (min-width: 576px){
    display: block;
  }
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid rgba(219, 225, 237, 0.7);
}