.hero {
  position: relative;
  // &:after {
  //   content: '';
  //   position: absolute;
  //   z-index: 5;
  //   top: -72px;
  //   left: 0;
  //   width: 100%;
  //   height: 182vw;
  //   background-image: url('./../img/bg/hero-mobile.png');
  //   @media only screen and (min-width: 992px){
  //     background-image: url('./../img/bg/hero-desktop.png');
  //     height: 56vw;
  //   }
  //   background-repeat: no-repeat;
  //   background-size: cover;
  // }

  
}

.hero-item {
  position: relative;
  padding: 1.5rem;
  @media only screen and (min-width: 576px){
    padding: 2rem;
  }
  @media only screen and (min-width: 768px){
    padding: 3rem;
  }
  @media only screen and (min-width: 992px){
    padding: 4rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $bg-gray;
    mix-blend-mode: multiply;
    border-radius: 16px;
    z-index: 5;
  }



  &__title {
    position: relative;
    z-index: 10;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.15;
    letter-spacing: 0.02em;
    color: $color-black;
    margin: 0;
    br {
      display: none;
    }
    @media only screen and (min-width: 576px){
      max-width: calc(100% - 250px);
      font-size: 28px;
    }
    @media only screen and (min-width: 768px){
      max-width: calc(100% - 300px);
      font-size: 36px;
    }
    @media only screen and (min-width: 992px){
      max-width: 700px;
      font-size: 42px;
      br {
        display: block;
      }
    }
  }

  &__text {
    position: relative;
    z-index: 10;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray;
    margin-top: 1rem;
    @media only screen and (min-width: 576px){
      max-width: calc(100% - 250px);
    }
    @media only screen and (min-width: 768px){
      max-width: calc(100% - 300px);
    }
    @media only screen and (min-width: 992px){
      max-width: 600px;
      margin-top: 1.5rem;
      font-size: 16px;
    }
  }

  &__button {
    position: relative;
    z-index: 10;
    margin-top: 1.5rem;
    width: 100%;
    @media only screen and (min-width: 576px){
      width: auto;
    }
  }


}

.hero-image {
  position: absolute;
  @media only screen and (min-width: 576px){
    display: block;
  }
  right: 0;
  top: -5px;
  display: none;
  height: 100%;
  @media only screen and (min-width: 576px){
    padding-right: 1rem;
  }
  @media only screen and (min-width: 768px){
    padding-right: 2rem;
  }
  @media only screen and (min-width: 900px){
    padding-right: 4rem;
  }
  @media only screen and (min-width: 992px){
    padding-right: 6rem;
  }
  img {
    height: 100%;
  }
  
}

.hero-round {
  position: absolute;
  filter: blur(120px);
  z-index: 5;
}

.hero-round_1 {
  right: -150px;
  top: -80px;
  width: 264px;
  height: 264px;
  background: rgba(78, 76, 209, 0.2);
  @media only screen and (min-width: 1200px){
    right: -70px;
    top: -40px;
    width: 308px;
    height: 308px;
  }
}

.hero-round_2 {
  right: -40px;
  bottom: -40px;
  width: 275px;
  height: 275px;
  background: rgba(255, 138, 3, 0.2);
  @media only screen and (min-width: 1200px){
    right: 20px;
  }
}

.hero-round_3 {
  left: -40px;
  top: -16px;
  width: 205px;
  height: 205px;
  background: rgba(90, 200, 250, 0.2);
  @media only screen and (min-width: 1200px){
    left: 700px;
  }
}