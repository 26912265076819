.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  z-index: 10;
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-nav_mobile {
    @media only screen and (min-width: 992px){
      display: none;
    }
  }

}

.header-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .header-nav__link {
    @media only screen and (max-width: 992px){
      display: none;
    }
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    transition: $trans--300;
    &:hover {
      color: $color-blue;
    }
    &:first-child {
      font-weight: bold;
    }
    &:not(:last-child){
      margin-right: 1.5rem;
      @media only screen and (min-width: 1200px){
        margin-right: 3rem;
      }
    }
  }
}