main > .section {
  margin-bottom: 4rem;
  @media only screen and (min-width: 768px) {
    margin-bottom: 6rem;
  }
  @media only screen and (min-width: 992px) {
    margin-bottom: 8rem;
  }
  @media only screen and (min-width: 1200px) {
    margin-bottom: 10rem;
  }
}

.section-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.15;
  color: $color-black;
  margin: 0;
  @media only screen and (min-width: 768px) {
    font-size: 28px;
    text-align: center;
  }
  @media only screen and (min-width: 992px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 38px;
  }
}

.overlay {
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(48, 48, 48, 0.25);
  z-index: 990;
  transition: $trans--600;
}

.overlay.active {
  display: block;
  opacity: 1;
}
