.calc-total {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;

  &__column1 {
    grid-column: 1;
  }

  &__column2 {
    grid-column: 2;
  }

  &__image {
    height: 100px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.15;
    color: $color-gray;
    margin-bottom: 4px;
  }

  &__total {
    font-size: 32px;
    line-height: 1.15;
    color: $color-black;
  }

  &__description {
    font-size: 12px;
    line-height: 14px;
    color: $color-gray;
  }

  &__button {
    margin-top: 2rem;
  }

}

.calc-fixed {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  @media only screen and (min-width: 576px){
    display: none;
  }

  // padding: 1.5rem 1rem;
  // background: #F5F6F8;
  // border: 1px solid #E5E5E5;
  // border-radius: 12px 12px 0px 0px;

  &__title {
    grid-column: 1;
    grid-row: 1;
    font-size: 12px;
    line-height: 1;
    color: $color-gray;
  }

  &__total {
    grid-column: 1;
    grid-row: 2;
    font-size: 22px;
    line-height: 1.15;
    color: $color-gray;
    white-space: nowrap;
  }

  &__button {
    grid-column: 2;
    grid-row: span 2;
    text-align: right;
  }
}
