
.information-grid {
  display: grid;
  gap: 1rem;
  @media only screen and (min-width: 992px){
    grid-template-columns: 2fr 1fr;

    &__promo {
      grid-column: span 2;
      grid-row: 1;
    }

    &__info {
      grid-column: 1;
      grid-row: 2;
    }

    &__step {
      grid-column: 2;
      grid-row: 2;
    }
  }
}

.information-promo {
  position: relative;
  background-color: $bg-gray;
  border-radius: 1rem;
  padding: 4rem 1.5rem 1.5rem 1.5rem;

  & > * {
    position: relative;
    z-index: 10;
  }

  @media only screen and (max-width: 576px){
    margin-top: 100px;
  }

  @media only screen and (min-width: 576px){
    padding: 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;
  }
  @media only screen and (min-width: 768px){
    padding: 2.5rem;
  }
  @media only screen and (min-width: 992px){
    padding: 3.375rem;
  }

  &__image {
    height: 300px;
    width: auto;
    @media only screen and (max-width: 576px){
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
      height: 150px;
      // width: auto;
    }
  }

  &__info {
    margin-top: 0; //1.25rem;
    max-width: 635px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 0.02em;
    color: $color-black;
    margin: 0 0 0.75rem 0;
  }

  &__description {
    font-size: 14px;
    line-height: 1.5;
    color: $color-gray;
  }

  &__price {
    font-size: 24px;
    line-height: 1;
    color: $color-black;
    margin-top: 1rem;
  }

  &__buttons {
    display: grid;
    gap: 0.75rem;
    margin-top: 1.5rem;
    @media only screen and (min-width: 768px){
      grid-template-columns: auto auto 1fr;
    }
  }
}

.information-box {
  padding: 1rem;
  background-color: $bg-gray;
  border-radius: 1rem;
  @media only screen and (min-width: 576px){
    padding: 1.5rem;
  }
  @media only screen and (min-width: 772px){
    padding: 2rem;
  }
  @media only screen and (min-width: 992px){
    padding: 2.5rem;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.15;
    color: $color-black;
    margin: 0 0 0.75rem 0;
    @media only screen and (min-width: 992px){
      font-size: 22px;
      margin: 0 0 1.5rem 0;
    }
  }

}

.information-description {

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $color-black;
    margin: 0 0.5rem 0 0;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    color: $color-black;
    @media only screen and (min-width: 992px){
      font-size: 16px;
    }
    &:not(:last-child){
      margin: 0 0 1.5rem 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  ul {
    padding-left: 1.25rem;
    margin: .5rem 0 1.5rem;
    li {
      font-size: 16px;
      line-height: 1.75;
      &::marker {
        color: $color-blue;
      }
    }
  }

}

.information-step {

  &:not(:last-child){
    margin-bottom: 1rem;
    @media only screen and (min-width: 992px){
      margin-bottom: 2rem;
    }
  }

  &__subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $color-black;
    margin: 0 0.5rem 0 0;
  }

  &__description {
    font-size: 14px;
    line-height: 1.5;
    color: $color-gray!important;
    margin: 0!important;
  }
}

.information-round {
  position: absolute;
  filter: blur(120px);
  z-index: 5;
}

.information-round_1 {
  left: calc(100% + 85px);
  top: -75px;
  width: 264px;
  height: 264px;
  @media only screen and (min-width: 768px){
    left: 85px;
    top: -45px;
    width: 308px;
    height: 308px;
  }
  background: rgba(78, 76, 209, 0.2);
}

.information-round_2 {
  left: calc(100% + 35px);
  bottom: 55px;
  width: 245px;
  height: 245px;
  @media only screen and (min-width: 768px){
    left: -16px;
    top: 100px;
    width: 275px;
    height: 275px;
  }
  background: rgba(255, 138, 3, 0.2);
}

.information-round_3 {
  left: -41px;
  top: -16px;
  width: 205px;
  height: 205px;
  @media only screen and (min-width: 768px){
    left: -180px;
    top: 10px;
    width: 205px;
    height: 205px;
  }
  background: rgba(90, 200, 250, 0.2);
}