.input {
  position: relative;
}

form > .input + .input,
form > .input + .input-grid,
form > .input-grid + .input-grid,
form > .input-grid + .input,
form > .input + .checkout  {
  margin-top: 2rem;
}

.input-title {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.15;
  color: $color-black;
  margin-bottom: 1rem;
}

.input-title_2level {
  @media only screen and (max-width: 992px){
    font-size: 14px;
    color: #9BA3B1;
  }
}

.input-box {
  position: relative;
}

.input-label {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: $color-gray;
  margin-bottom: 0.5rem;
}

.input-text {
  position: relative;
  overflow: hidden;
  input[type=text],
  input[type=email],
  textarea {
    background: #FFFFFF;
    padding: 0.75rem;
    width: 100%;
    border: 1px solid #DBE1ED;
    border-radius: 0.5rem;
    font-size: 14px;
    color: $color-black;
    outline: none!important;
    transition: $trans--300;
    &:placeholder-shown + .input-reset {
      display: none;
    }
    &:focus {
      background: #f8fbff;
      border-color: rgba(0, 111, 255, 0.3);
    }
  }
}

.input-radio {
  label {
    display: inline-flex;
    font-size: 14px;
    padding: 10px 12px;
    line-height: 1;
    color: $color-black;

    background: #F7F8FB;
    border: 1px solid rgba(48, 48, 48, 0.1);
    border-radius: 6px;
    cursor: pointer;

    transition: $trans--300;
  }

  input[type=radio] {
    display: none;
  }

  input[type=radio]:checked + label {
    background: #E7F3FF;
    color: $color-blue;
    border-color: rgba(0, 111, 255, 0.3);
  }
}

.input-checkbox {
  label {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 12px;
    line-height: 1;
    color: $color-black;

    background: #F7F8FB;
    border: 1px solid rgba(48, 48, 48, 0.1);
    border-radius: 6px;
    cursor: pointer;

    transition: $trans--300;

    &:after {
      content: '';
      display: none;
      position: absolute;
      right: 32px;
      height: 1rem;
      border-left: 1px solid rgba(152, 159, 168, 0.2);
    }

    svg {
      display: none;
      margin-left: 18px;
    }

  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox]:checked + label {
    background: #E7F3FF;
    color: $color-blue;
    border-color: rgba(0, 111, 255, 0.3);

    &:after {
      display: block;
    }

    svg {
      display: block;
    }
  }
}

.input-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -.5rem;

  .input-radio:not(:last-child),
  .input-checkbox:not(:last-child) {
    margin-right: .5rem;
    margin-bottom: .5rem;
  }
}

.input-grid {
  display: grid;
  column-gap: 1rem;
  // row-gap: 1.5rem;

  // .input {
  //   margin-top: 0!important;
  // }

  &__column {
    .input + .input {
      margin-top: 1rem;
    }
  }

  &__title {
    @extend .input-title;
    margin-top: 0;
    @media only screen and (min-width: 992px){
      display: none;
    }
  }

  .input-grid__column + .input-grid__column {
    @media only screen and (max-width: 992px){
      margin-top: 1rem;
    }
  }

}

.input-grid_1_md_2 {
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.input-grid_different {
  .input-grid__column + .input-grid__column {
    @media only screen and (max-width: 992px){
      margin-top: 2rem;
    }
  }
}

.input-grid-cell_reset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 992px) {
    grid-column: span 2;
  }
}

.input-reset {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.75rem;
  border: 0;
  height: 100%;
  background: transparent;
}

.input-add {
  margin-top: -0.75rem;
}

::placeholder {
  color: #C5CED9;
}

// Required
.input_required {
  .input-label:after {
    content: '\2022';
    color: #FF3233;
    margin-left: 0.15rem;
  }
}

// Error
.input-message {
  display: none;
}

.input_error {
  input[type=text]:not(:empty)   {
    border-color: #FF3233;
  }
  .input-message {
    display: block;
    font-size: 12px;
    line-height: 1;
    color: #FF3233;
    margin-top: 0.4rem;
  }
}