.catalog {
  @media only screen and (max-width: 992px){
    .container {
      padding: 0;
    }
  }
}

.catalog-grid {
  // display: grid;
  // grid-template-columns: repeat(5, 1fr);
  // grid-gap: 1rem;
  display: flex;
  @media only screen and (max-width: 992px){
    padding-left: 16px;
  }
  .tns-item {
    display: inline-flex!important;
  }
}

.catalog-offer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 1rem;
  background: $bg-gray;
  border-radius: 1rem;

  min-width: 236px;
  margin-top: 60px;

  &__image {
    position: relative;
    top: -60px;
    height: 100px;
    img {
      margin: 0 auto;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    color: $color-black;
    margin-top: 1rem;
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: $color-gray;
    margin-top: 0.5rem;
    flex-grow: 1;
  }

  &__button {
    margin-top: 1rem;
    width: 100%;
  }


}