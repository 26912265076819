.checkout {
  display: none;
  padding: 1rem;
  background: #F5F6F8;
  border-radius: 10px;
}

.checkout-title {
  font-size: 16px;
  line-height: 1;
  color: $color-black;
  margin-bottom: 1rem;
}

.checkout-list {
  list-style: none;
  padding: 0;
}

.checkout-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // color: $color-black; 

  font-size: 12px;
  line-height: 1.15;
  color: #767C87;

  &:before {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0;
    bottom: 5px;
    width: 100%;
    border-bottom: 1px dashed rgba(118, 127, 137, 0.2);
    z-index: 3;
  }

  span {
    position: relative;
    display: inline-block;
    background: #F5F6F8;
    z-index: 5;
    &:first-child {
      padding-right: 5px;
      flex-shrink: 0;
    }
    &:last-child {
      padding-left: 5px;
      text-align: right;
    }
  }

  &:not(:last-child){
    margin-bottom: 10px;
  }

  &:last-child {
    font-weight: bold;
    font-size: 14px;
    color: $color-black;
  }
}