.contact {
  background-color: #fff;
  @media only screen and (min-width: 576px){
    box-shadow: 0px 5px 10px rgba(66, 76, 96, 0.07);
    border-radius: 12px;
    padding: 2rem 1rem;
    max-width: 410px;
    // margin: 4rem 0 4rem 4rem;
  }
}

.contact-head {
  padding-bottom: 1.5rem;
}

.contact-body {
  padding: 2rem 0;
}

.contact-footer {
  padding-top: 1.5rem;
}

.contact-head + .contact-body {
  border-top: 1px solid #F1F3F8;
}

.contact-body + .contact-footer {
  border-top: 1px solid #F1F3F8;
}

.contact-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.05;
  margin: 0;
}

.contact-item {

  &:not(:last-child){
    margin-bottom: 2rem;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: $color-black;
    margin-bottom: 0.5rem;
    svg + span {
      margin-left: 0.5rem;
    }
  }

  &__value {
    font-size: 14px;
    color: $color-gray;
  }

  &__columns {
    display: flex;
  }

  &__column {
    display: flex;
    flex-direction: column;
    span {
      color: #A7ACB7;
      font-size: 12px;
      line-height: 1.15;
      margin-bottom: 2px;
    }
    &:not(:last-child){
      margin-right: 1.5rem;
    }
  }

}

.map-contact {
  position: relative;
  @media only screen and (min-width: 576px){
    padding: 4rem 0 4rem 1rem;
  }
  @media only screen and (min-width: 992px){
    padding: 4rem 0 4rem 4rem;
  }
}

.map {
  .container {
    position: relative;
  }
}

.map-map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  border-radius: 1rem;
  overflow: hidden;
  background-color: lightgray;
  z-index: -1;
}