.quick-grid {
  position: relative;
  display: grid;
  @media only screen and (min-width: 768px){
    grid-template-columns: 2fr 2fr;
  }
  gap: 1.5rem;
  position: relative;
  padding: 1.5rem;
  @media only screen and (min-width: 768px){
    padding: 2rem;
  }
  @media only screen and (min-width: 992px){
    padding: 2.5rem;
  }
  @media only screen and (min-width: 1200px){
    padding: 3rem;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $bg-gray;
    mix-blend-mode: multiply;
    border-radius: 16px;
    z-index: 5;
  }

  & > * {
    position: relative;
    z-index: 10;
  }
}

.quick-info {

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.15;
    color: $color-black;
    margin: 0;
    @media only screen and (min-width: 768px){
      font-size: 28px;
    }
    @media only screen and (min-width: 992px){
      font-size: 32px;
    }
    @media only screen and (min-width: 1200px){
      font-size: 38px;
    }
  }

  &__description {
    font-size: 14px;
    @media only screen and (min-width: 768px){
      font-size: 16px;
    }
    line-height: 23px;
    color: $color-gray;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.quick-form {
  display: flex;
  flex-direction: column;

  &__row:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &__grid {
    display: grid;
    gap: 1rem;
    @media only screen and (min-width: 1200px){
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .input {
    margin-top: 0!important;
  }
}

.quick-round {
  position: absolute;
  filter: blur(120px);
  z-index: 5;
}

.quick-round_1 {
  left: 28px;
  top: 0;
  width: 247px;
  height: 247px;
  background: rgba(78, 76, 209, 0.2);
}

.quick-round_2 {
  left: -16px;
  bottom: -48px;
  width: 187px;
  height: 187px;
  background: rgba(255, 138, 3, 0.2);
}

.quick-round_3 {
  left: 358px;
  top: 10px;
  width: 205px;
  height: 205px;
  background: rgba(90, 200, 250, 0.2);
}