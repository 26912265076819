// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/tiny-slider";
// @import "vendor/swiper";

// Global
//---------------------------------

@import "variables";
@import "mixins";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";
@import "global/button";
@import "global/input";
@import "global/main";

// Blocks
//---------------------------------

@import "blocks/header";
@import "blocks/hero";
@import "blocks/catalog";
@import "blocks/featured";
@import "blocks/how";
@import "blocks/quick";
@import "blocks/company";
@import "blocks/sertificate";
@import "blocks/faq";
@import "blocks/contact";
@import "blocks/information";
@import "blocks/main-nav";
@import "blocks/mobile-nav";
@import "blocks/calc";
@import "blocks/success";
@import "blocks/checkout";
@import "blocks/footer";
@import "blocks/modal";


// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
