.faq {
  .container {
    max-width: 780px;
  }
}

.faq-title {
  margin-bottom: 1rem;
  @media screen and (min-width: 992px){
    margin-bottom: 2rem;
  }
}

.faq-item {
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  gap: 0.5rem;
  padding: 1.5rem 0;
  box-shadow: inset 0px -1px 0px #E8E9EB;
  cursor: pointer;
}

.faq-item.active {
  .faq-info__quastion,
  .faq-number {
    color: $color-blue;
  }
  .faq-button button {
    color: $color-blue;
    transform: rotate(-180deg);
  }
}

.faq-number {
  font-weight: 600;
  font-size: 16px;
  color: $color-gray;
  transition: color $trans--300;
}

.faq-info {
  overflow: hidden;
  

  &__quastion {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
    background-color: $color-white;
    z-index: 2;
    transition: color $trans--300;
  }

  &__answer {
    // display: none;
    height: 0;
    margin-top: 0.75rem;
    font-size: 14px;
    color: $color-gray;
    transition: height $trans--300;
  }
}

.faq-button {
  button {
    color: $color-gray;
  }
}