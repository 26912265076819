.footer {
  min-height: 84px;
  padding: 2rem 0;
  background: $bg-gray;
}

.footer-row {
  @media only screen and (min-width: 992px){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer-logo {
  display: block;
  margin-bottom: 2rem;
  @media only screen and (min-width: 992px){
    margin-bottom: 0;
  }
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1.5rem;
  &__link {
    font-size: 12px;
    @media only screen and (min-width: 992px){
      font-size: 14px;
    }
    color: $color-gray;
    line-height: 1;
    margin-bottom: 1.5rem;
    transition: $trans--300;
    &:hover {
      color: $color-blue;
    }
    &:not(:last-child){
      margin-right: 14px;
      @media only screen and (min-width: 1200px){
        margin-right: 2rem;
      }
    }
  }
}