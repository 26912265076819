.featured-title {
  margin-bottom: 2rem;
  @media only screen and (min-width: 772px) {
    margin-bottom: 2.5rem;
  }
  @media only screen and (min-width: 992px) {
    margin-bottom: 3rem;
  }
}

.featured-grid {
  display: grid;
  gap: 1.5rem;
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.featured-item {
  display: grid;
  grid-template-columns: 3rem auto;
  @media only screen and (min-width: 576px) {
    grid-template-columns: 3.5rem auto;
  }
  @media only screen and (min-width: 768px) {
    grid-template-columns: 4rem auto;
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: auto;
    text-align: center;
  }
  column-gap: 1rem;
  row-gap: 0.25rem;

  &__icon {
    grid-column: 1;
    grid-row: span 2;
    @media only screen and (min-width: 992px) {
      grid-row: 1;
      display: inline-flex;
      justify-self: center;
      margin-bottom: 1.5rem;
    }
  }

  &__title {
    grid-column: 2;
    grid-row: 1;
    @media only screen and (min-width: 992px) {
      grid-column: 1;
      grid-row: 2;
    }

    font-weight: 600;
    font-size: 14px;
    @media only screen and (min-width: 576px) {
      font-size: 16px;
    }
    line-height: 1.25;
    color: $color-black;
  }

  &__description {
    grid-column: 2;
    grid-row: 2;
    @media only screen and (min-width: 992px) {
      grid-column: 1;
      grid-row: 3;
    }

    font-size: 12px;
    @media only screen and (min-width: 576px) {
      font-size: 14px;
    }
    line-height: 1.25;
    color: $color-gray;
  }
}

.featured-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  width: 100%;
  @media only screen and (min-width: 992px) {
    width: 4.5rem;
  }
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, $color-blue 0%, $color-blue 100%);
  box-shadow: 0px 10px 20px -2px rgba(0, 111, 255, 0.3);
  border-radius: 50%;
  &:before {
    content: '';
    padding-top: 100%;
    display: block;
  }
  svg {
    height: 24px!important;
    width: 24px!important;
    @media only screen and (min-width: 576px) {
      height: 28px!important;
      width: 28px!important;
    }
    @media only screen and (min-width: 768px) {
      height: 32px!important;
      width: 32px!important;
    }
    @media only screen and (min-width: 992px) {
      height: 36px!important;
      width: 36px!important;
    }
  }
}