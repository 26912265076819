.how-title {
  margin-bottom: 3rem;
  @media only screen and (min-width: 772px) {
    margin-bottom: 4rem;
  }
  @media only screen and (min-width: 1200px) {
    margin-bottom: 6rem;
  }
}

.how-grid {
  position: relative;
  z-index: 10;
  @media only screen and (min-width: 772px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

.how-item {
  position: relative;

  @media only screen and (max-width: 772px) {
    &:not(:last-child){
      margin-bottom: 3.5rem;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    svg, img {
      transform: scale(0.6) translateX(-40%) translateY(calc(40% - 8px));
      @media only screen and (min-width: 772px) {
        transform: translateY(20%);
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $color-black;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    color: $color-gray;
    margin-top: 0.5rem;
  }
}
