button {
  cursor: pointer;
  &:disabled {
    opacity: .5;
  }
}

.btn {
  display: inline-block;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 14px 28px;
  border-radius: 8px;
  transition: background-color $trans--300;
  cursor: pointer;
  text-align: center;
}

.btn-sm {
  font-size: 14px;
  padding: 10px 18px;
}

.btn-w100 {
  width: 100%;
}

.btn-primary {
  color: $color-white;
  background-color: #006FFF;
  &:hover:not(:disabled) {
    background-color: #005CD2;
  }
  &:active:not(:disabled) {
    background-color: #0054C1;
  }
}

.btn-light {
  background-color: #FFFFFF;
  color: #006FFF;
  border: 1px solid rgba(0, 111, 255, 0.2);
  &:hover:not(:disabled) {
    background-color: #F2F8FF;
  }
  &:active:not(:disabled) {
    background-color: #EDF5FF;
  }
}

.btn-link {
  padding: 0;
  text-align: left;
  font-size: 12px;
  line-height: 1;
  color: $color-blue;
}