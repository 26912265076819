.company-title {
  text-align: left!important;
}

.company-info {
  position: relative;
  display: grid;

  & > * {
    position: relative;
    z-index: 10;
  }
  @media only screen and (min-width: 992px){
    grid-template-columns: 2fr 1fr;
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: $color-gray;
  }

  &__icon {
    margin: 0 auto;
    @media only screen and (max-width: 992px){
      display: none;
    }
  }
}


.company-round {
  display: none;
  @media only screen and (min-width: 992px){
    display: block;
  }
  position: absolute;
  filter: blur(120px);
  z-index: 5;
}

.company-round_1 {
  right: 150px;
  top: 48px;
  width: 247px;
  height: 247px;
  background: rgba(78, 76, 209, 0.2);
}

.company-round_2 {
  right: 90px;
  top: 0;
  width: 187px;
  height: 187px;
  background: rgba(255, 138, 3, 0.2);
}